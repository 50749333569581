export interface SuccessResponse {
  success: boolean;
}

export type MeasurementSystem = 'metric' | 'imperial';

export type OrganizationType = 'sport' | 'health' | 'personalTraining';

export type ArchivedFilterState = 'all' | 'archived' | 'active';

export const measurementSystemsWeightMapping: Record<MeasurementSystem, string> = {
  'metric': 'kg',
  'imperial': 'lb'
}


export interface TableHeader {
  key: string;
  translationKey: string;
  /** If translation key is not found, fallback English translation */
  fallbackLabel: string;
}

// Define an interface for table rows
export interface TableRow {
  [key: string]: {
    type: 'text';
    value: string;
  } | {
    type: 'translation';
    value: string;
  }
}

// Define the main interface for tabular data
export interface TabularData {
  headers: TableHeader[]; // Array of table headers
  rows: TableRow[]; // Array of table rows
}

export interface ArchiveEntityRequest {
  isArchived: boolean;
}

export interface GraphItem {
  x: string;
  y: number;
  itemId: string;
  metadata: {
    groupId: string;
    loggedWorkoutId: string;
  } | {
    loggedWorkoutId: string;
  }
}

export interface GraphItemGroup {
  groupName: string;
  groupId: string;
  items: GraphItem[];
  // item 1 is exercise total volume on day 1
  // item 2 is exercise total volume on day 2
  // item 3 is exercise total volume on day 3
}

export interface GraphData {
  datasets: GraphItemGroup[];
  // dataset 1 is exercise a
  // dataset 2 is exercise b
  // dataset 3 is exercise c
  graphType: 'line';
}
