import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { DirectivesModule } from "../directives/directives.module";
import { CustomerCreateModalComponent } from "../modals/customer-create-modal/customer-create-modal.component";
import { PipesModule } from "../pipes/pipes.module";
import { CalendarGroupListComponent } from "./calendar-group-list/calendar-group-list.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { CustomerInviteComponent } from "./customer-invite/customer-invite.component";
import { CustomerIonItemComponent } from "./customer-ion-item/customer-ion-item.component";
import { CustomerSearchListComponent } from "./customer-search-list/customer-search-list.component";
import { CustomersCellRendererComponent } from "./customers-cell-renderer.component";
import { EmployeeInviteComponent } from "./employee-invite/employee-invite.component";
import { ExerciseContainerComponent } from "./exercise-container/exercise-container.component";
import { ExerciseFieldFilterComponent } from "./exercise-field-filter/exercise-field-filter.component";
import { GoogleSignInButtonComponent } from "./google-sign-in-button/google-sign-in-button.component";
import { GroupCriteriaComponent } from "./group-criteria/group-criteria.component";
import { InlineSearchComponent } from "./inline-search/inline-search.component";
import { LoggedWorkoutItemComponent } from "./logged-workout-item/logged-workout-item.component";
import { LogoComponent } from "./logo/logo.component";
import { NotificationsBadgeComponent } from "./notifications-badge/notifications-badge.component";
import { NotificationsPopoverComponent } from "./notifications-popover/notifications-popover.component";
import { OnboardingProgressMenuItemComponent } from "./onboarding-progress-menu-item/onboarding-progress-menu-item.component";
import { ProfileImageComponent } from "./profile-image/profile-image.component";
import { ProfileImagesContainerComponent } from "./profile-images-container/profile-images-container.component";
import { SvgProgressComponent } from "./svg-progress/svg-progress.component";
import { VideoUploadComponent } from "./video-upload/video-upload.component";
import { WorkoutCopyTimelineIllustrationComponent } from "./workout-copy-timeline-illustration/workout-copy-timeline-illustration.component";
import { WorkoutIonItemComponent } from "./workout-ion-item/workout-ion-item.component";
import { WorkoutStepsListComponent } from "./workout-steps-list/workout-steps-list.component";
import { TagBadgeComponent } from "./tag-badge/tag-badge.component";
import { TagSelectComponent } from "./tag-select/tag-select.component";
import { WorkoutsFilterButtonComponent } from "./workouts-filter-button/workouts-filter-button.component";
import { CustomFieldComponent } from "./custom-field/custom-field.component";
import { WorkoutStepComponent } from "./workout-steps-list/workout-step/workout-step.component"
import { WorkoutHeaderComponent } from "./workout-header/workout-header.component";
import { UnitOfMeasureComponent } from "./unit-of-measure/unit-of-measure.component";
import { LogWorkoutFormComponent } from "./log-workout-form/log-workout-form.component";
import { LoggedWorkoutStepsListComponent } from "./logged-workout-steps-list/logged-workout-steps-list.component";
import { NgxIntlModule } from "../ngx-intl/ngx-intl.module";
import { EntityDisplayedDateComponent } from "./entity-displayed-date/entity-displayed-date.component";
import { ExerciseVideoComponent } from "./exercise-video/exercise-video.component";
import { TagFilterButtonComponent } from "./tag-filter-button/tag-filter-button.component";
import { TagSelectColorPickerComponent } from "./tag-select-color-picker/tag-select-color-picker.component";
import { BannerComponent } from "./banner/banner.component";
import { IsNewBadgeComponent } from "./is-new-badge/is-new-badge.component";
import { ValueEditorComponent } from "./value-editor/value-editor.component";
import { TimeInputCellComponent } from "./time-input-cell/time-input-cell.component";
import { TabularDataComponent } from "./tabular-data/tabular-data.component";
@NgModule({
  imports: [
    IonicModule,
    TranslateModule.forChild(),
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    RouterModule,
    NgxIntlModule,
  ],
  exports: [
    CalendarComponent,
    ProfileImageComponent,
    ProfileImagesContainerComponent,
    LogoComponent,
    ExerciseContainerComponent,
    CustomerInviteComponent,
    GoogleSignInButtonComponent,
    EmployeeInviteComponent,
    ExerciseFieldFilterComponent,
    CustomersCellRendererComponent,
    NotificationsBadgeComponent,
    NotificationsPopoverComponent,
    WorkoutStepsListComponent,
    CustomerSearchListComponent,
    InlineSearchComponent,
    CalendarGroupListComponent,
    CustomerIonItemComponent,
    WorkoutIonItemComponent,
    LoggedWorkoutItemComponent,
    WorkoutCopyTimelineIllustrationComponent,
    TagSelectComponent,
    TagBadgeComponent,
    VideoUploadComponent,
    GroupCriteriaComponent,
    SvgProgressComponent,
    OnboardingProgressMenuItemComponent,
    CustomerCreateModalComponent,
    WorkoutsFilterButtonComponent,
    CustomFieldComponent,
    WorkoutHeaderComponent,
    UnitOfMeasureComponent,
    WorkoutStepComponent,
    LogWorkoutFormComponent,
    LoggedWorkoutStepsListComponent,
    EntityDisplayedDateComponent,
    ExerciseVideoComponent,
    TagFilterButtonComponent,
    TagSelectColorPickerComponent,
    BannerComponent,
    IsNewBadgeComponent,
    ValueEditorComponent,
    TimeInputCellComponent,
    TimeInputCellComponent,
    TabularDataComponent
  ],
  declarations: [
    CalendarComponent,
    ProfileImageComponent,
    ProfileImagesContainerComponent,
    LogoComponent,
    ExerciseContainerComponent,
    CustomerInviteComponent,
    GoogleSignInButtonComponent,
    EmployeeInviteComponent,
    ExerciseFieldFilterComponent,
    CustomersCellRendererComponent,
    NotificationsBadgeComponent,
    NotificationsPopoverComponent,
    WorkoutStepsListComponent,
    CustomerSearchListComponent,
    InlineSearchComponent,
    CalendarGroupListComponent,
    CustomerIonItemComponent,
    WorkoutIonItemComponent,
    LoggedWorkoutItemComponent,
    WorkoutCopyTimelineIllustrationComponent,
    TagSelectComponent,
    TagBadgeComponent,
    VideoUploadComponent,
    GroupCriteriaComponent,
    SvgProgressComponent,
    OnboardingProgressMenuItemComponent,
    CustomerCreateModalComponent,
    WorkoutsFilterButtonComponent,
    CustomFieldComponent,
    WorkoutStepComponent,
    WorkoutHeaderComponent,
    UnitOfMeasureComponent,
    LogWorkoutFormComponent,
    LoggedWorkoutStepsListComponent,
    EntityDisplayedDateComponent,
    ExerciseVideoComponent,
    TagFilterButtonComponent,
    TagSelectColorPickerComponent,
    BannerComponent,
    IsNewBadgeComponent,
    ValueEditorComponent,
    TimeInputCellComponent,
    TabularDataComponent
  ],
})
export class ComponentsModule { }
