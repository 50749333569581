import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEqual, isFunction, isNil, isObject } from "lodash-es";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { SuccessResponse } from "../../../../../libs/typings/src/lib/common";

export type StateSupportedHttpMethods = 'get' | 'post' | 'put' | 'delete' | 'patch';

export interface CreateStateOpts<REQ, RES, PathParts = { [key: string]: string }> {
  path: string;
  httpMethod: StateSupportedHttpMethods;
  // Useful for side effects, like re-fetching the list once an item is created or updated.
  onNewResponse?: (state: State<REQ, RES, PathParts>) => void;

  headers?: { [key: string]: string };
}

export interface StateAction<REQ, PathParts = { [key: string]: string }> {
  request?: REQ;
  // If the path has placeholders, the pathParts will be used to replace them.
  pathParts?: PathParts;

  /**
   * This will return the last response if your request and pathParts are the same as the last request.
   * this is a useful caching mechanism
  */
  getLastResponse?: boolean;
}

export interface State<REQ, RES, PathParts = { [key: string]: string }> {
  request?: REQ;
  response?: RES;
  isLoading: boolean;
  error: any;
  pathParts?: PathParts;
}

/** Will represent a piece of state in our application state */
export class StatePieceManager<REQ, RES, PathParts = { [key: string]: string }> {

  private defaultState = {
    request: undefined,
    response: undefined,
    isLoading: false,
    error: undefined,
    pathParts: undefined
  }

  state$ = new BehaviorSubject<State<REQ, RES, PathParts>>(this.defaultState);


  constructor(
    private opts: CreateStateOpts<REQ, RES, PathParts>,
    private httpClient: HttpClient
  ) {
    if (isFunction(this.opts.onNewResponse)) {
      this.state$.pipe(
        startWith(null),
        distinctUntilChanged((prev, next) => isEqual(prev?.response, next?.response)),
        filter(state => !isNil(state?.response))
      )
        .subscribe((state) => {
          this.opts.onNewResponse!(state!);
        });
    }
  }

  async call(action: StateAction<REQ, PathParts> = {}) {

    let promise = this.callWithoutStateMutation(action);

    try {
      this.state$.next({
        ...this.defaultState,
        isLoading: true,
        request: action.request,
        pathParts: action.pathParts
      });

      const response = await promise;

      if ('success' in (response as SuccessResponse) && !(response as SuccessResponse).success) {
        const reason = 'Success=false operation is not successful';
        promise = Promise.reject(response);
        throw new Error(reason);
      }

      this.state$.next({
        ...this.state$.value,
        response
      });
    } catch (error) {
      this.state$.next({
        ...this.state$.value,
        error,
        response: undefined,
      });
    } finally {
      this.state$.next({
        ...this.state$.value,
        isLoading: false,
      });
    }

    return promise;
  }

  callWithoutStateMutation(action: StateAction<REQ, PathParts> = {}) {
    // If getLastResponse is set to true and the request is the same as the last request, and the pathParts are the same, then return the last response
    // IF there is one
    //
    if (action.getLastResponse && isEqual(this.state$.value.request, action.request) && isEqual(this.state$.value.pathParts, action.pathParts) && !isNil(this.state$.value.response)) {
      return this.state$.value.response;
    }

    const { httpMethod, path, headers } = this.opts;

    const pathIncludingParts = this.generatePath(path, action.pathParts);

    let promise: Promise<RES> = Promise.resolve(undefined as RES);

    const options = {
      headers
    }

    if (httpMethod === 'get') {
      promise = firstValueFrom(this.httpClient.get<RES>(pathIncludingParts, options));
    } else if (httpMethod === 'post') {
      promise = firstValueFrom(this.httpClient.post<RES>(pathIncludingParts, action.request, options));
    } else if (httpMethod === 'put') {
      promise = firstValueFrom(this.httpClient.put<RES>(pathIncludingParts, action.request, options));
    } else if (httpMethod === 'delete') {
      promise = firstValueFrom(this.httpClient.delete<RES>(pathIncludingParts, {
        body: action.request,
        ...options
      }));
    } else if (httpMethod === 'patch') {
      promise = firstValueFrom(this.httpClient.patch<RES>(pathIncludingParts, action.request, options));
    } else {
      promise = Promise.reject('Unknown http method');
    }

    promise.catch((error) => {
      console.error(`[StateManager:call] error calling path=${pathIncludingParts}`, error);
    });

    return promise;
  }

  // Ensures that the path has placeholders, and replaces them with the values in the pathParts map.
  // example
  // path: '/api/customers/:customerId/workouts/:workoutId'
  // pathParts: { customerId: '1', workoutId: '2' }
  // returns: '/api/customers/1/workouts/2'
  private generatePath(originalPath: string, pathParts: PathParts | undefined) {
    let path = originalPath;

    if (isNil(pathParts)) {
      return path;
    }

    if (!isObject(pathParts)) {
      return path;
    }

    for (const [key, value] of Object.entries(pathParts)) {
      path = this.replaceAll(path, `:${key}`, value);
    }

    return path;
  }

  // https://stackoverflow.com/a/17606289/4047409
  private replaceAll(value: String, search: string, replacement: string) {
    return value.split(search).join(replacement);
  }

  getResponse$() {
    return this.state$.pipe(
      map(state => state.response)
    );
  }

  getError$() {
    return this.state$.pipe(
      map(state => state.error)
    );
  }

  isLoading$() {
    return this.state$.pipe(
      map(state => state.isLoading)
    );
  }

  getRequest$() {
    return this.state$.pipe(
      map(state => state.request)
    );
  }

  clear() {
    this.state$.next(this.defaultState);
  }

  setState(newState: Partial<State<REQ, RES, PathParts>>) {
    this.state$.next({
      ...this.state$.value,
      ...newState
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {
  // We will maintain a local list of all the states we have created.
  // This will allow us to clear the state when we need to
  // the key will be generated based on the options passed to createState()
  //
  statePieceMapping = new Map<string, StatePieceManager<unknown, unknown, unknown>>();

  constructor(private http: HttpClient) { }

  createState<REQ, RES, PathParts = { [key: string]: string }>(opts: CreateStateOpts<REQ, RES, PathParts>) {
    const createdStatePiece = new StatePieceManager<REQ, RES, PathParts>(opts, this.http);

    const statePieceKey = this.generateKey(opts);

    if (this.statePieceMapping.get(statePieceKey)) {
      throw new Error(`We already have a statePiece registered for ${statePieceKey}`);
    }

    this.statePieceMapping.set(statePieceKey, createdStatePiece);

    return createdStatePiece;
  }

  /** Will clear all application state, useful when logging out */
  clearAllState() {
    this.statePieceMapping.forEach(statePiece => statePiece.clear());
  }

  private generateKey(opts: CreateStateOpts<unknown, unknown, unknown>) {
    return `${opts.path}-${opts.httpMethod}`;
  }
}
