import { ME_Fit } from 'me-fit-typings';

export type CreateChatRoomDto = {
  name?: string;
  customerIds: string[];
  employeeIds: string[];
  firstMessage: Pick<ChatMessage, 'message' | 'type'>;
}

export type ChatParticipant = {
  id: string;
  organizationCustomerId?: string;
  organizationEmployeeId?: string;
}

export type ChatMessage = {
  id: string;
  message: string;
  createdAt: string|Date;
  updatedAt: string|Date;
  type: ChatMessageType;
  createdByChatParticipant: ChatParticipant;
}


// transform the above schema into typescript
export type ChatRoom = {
  id: string;
  name?: string;
  description?: string;
  createdAt: string|Date;
  updatedAt: string|Date;
  messages: ChatMessage[];
  participants: ChatParticipant[];
  createdByChatParticipantId: string;
}

export const ChatMessageTypeObj = {
  text: 'text',
} as const

export type ChatMessageType = keyof typeof ChatMessageTypeObj;



/** This will be used to render the sideview in ME Fit Pro */
export type ChatListResponse = {
  chatList: ChatListItem[];
}

export type ChatListItem = {
  // customer?: ME_Fit.OrganizationCustomer;
  // employee?: ME_Fit.OrganizationEmployee;

  /** Depending on whether theres a conversation with this person or not */
  chatRoom?: ChatRoom;
}

export interface ListChatRoomForOrgChatRoom extends Omit<ChatRoom, 'messages'> {
  /** This will be used to render the sideview in ME Fit Pro */
  chatListItemTitle: string;
  chatListItemPhotoUrl?: string;
}

export interface ListChatRoomsForOrgRespone {
  chatRooms: Array<ListChatRoomForOrgChatRoom>;
}


export interface ListMessagesResponse {
  messages: Array<Omit<ChatMessage, 'room'>>;
}

export interface SendMessageRequest {
  message: string;
  type: ChatMessage['type'];
}
